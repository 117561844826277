/**
 * Price range slider
 */
if($('.pricerange').length){

    var initValues = [400000,1200000];
    var defaults = initValues;

    if($('.price').hasClass('homdes')){
        initValues = [150000,850000];
        defaults = initValues;
    }

    if($("#pricerange-min").val() > -1){
        initValues = [$("#pricerange-min").val(), $("#pricerange-max").val()];
    }else{
        $("#pricerange-min").val(initValues[0]);
        $("#pricerange-max").val(initValues[1]);
    }

    $(".pricerange").slider({
        range: true,
        min: defaults[0],
        max: defaults[1],
        values: initValues,
        create: function(event) {
            $('.pricerange .ui-slider-handle').first().append('<span class="value-1">'+processPrice(initValues[0])+'</span>');
            $('.pricerange .ui-slider-handle').last().append('<span class="value-2">'+processPrice(initValues[1])+'</span>');
        },
        slide: function( event, ui ) {
            $("#pricerange-min").val(Math.round(ui.values[0] / 1000) * 1000);
            $("#pricerange-max").val(Math.round(ui.values[1] / 1000) * 1000);

            $('.pricerange .ui-slider-handle .value-1').text(processPrice(ui.values[ 0 ]));
            $('.pricerange .ui-slider-handle .value-2').text(processPrice(ui.values[ 1 ]));
        }
    });


    function processPrice(price){
        price = parseInt(price);

        if(price >= 1000 && price < 1000000){
            return '$'+Math.floor(price/1000)+'K';
        }else if(price == 1000000){
            return '$1M+';
        }else if(price > 1000000){
            return '$'+(price/1000000).toFixed(2)+'M';
        }else{
            return '$'+price;
        }
    }
}


/**
 * House size slider
 */
if($('.housesize').length){

    if($('.housesize').hasClass('hal')){
        var unit = 'm<sup>2</sup>';
        var initValues = [150,1000];
    }else{
        var unit = 'sq';
        var initValues = [16,60];
    }

    var defaults = initValues;

    if($("#housesize-min").val() > -1){
        initValues = [$("#housesize-min").val(), $("#housesize-max").val()];
    }else{
        $("#housesize-min").val(initValues[0]);
        $("#housesize-max").val(initValues[1]);
    }

    $(".housesize").slider({
        range: true,
        min: defaults[0],
        max: defaults[1],
        values: initValues,
        create: function(event) {
            $('.housesize .ui-slider-handle').first().append('<span class="value-1">'+Math.round(initValues[0])+unit+'</span>');
            $('.housesize .ui-slider-handle').last().append('<span class="value-2">'+Math.round(initValues[1])+unit+'</span>');
        },
        slide: function( event, ui ) {
            $("#housesize-min").val(Math.round(ui.values[0]));
            $("#housesize-max").val(Math.round(ui.values[1]));

            $('.housesize .ui-slider-handle .value-1').empty().append(ui.values[ 0 ]+unit);
            $('.housesize .ui-slider-handle .value-2').empty().append(ui.values[ 1 ]+unit);
        }
    });

}



/**
 * Block width slider
 */
if($('.blockwidth').length){

    var initValues = [6,18];
    var defaults = initValues;

    if($("#blockwidth-min").val() > -1){
        initValues = [$("#blockwidth-min").val(), $("#blockwidth-max").val()];
    }else{
        $("#blockwidth-min").val(initValues[0]);
        $("#blockwidth-max").val(initValues[1]);
    }

    $(".blockwidth").slider({
        range: true,
        min: defaults[0],
        max: defaults[1],
        values: initValues,
        create: function(event) {
            $('.blockwidth .ui-slider-handle').first().append('<span class="value-1">'+Math.round(initValues[0])+'m</span>');
            $('.blockwidth .ui-slider-handle').last().append('<span class="value-2">'+Math.round(initValues[1])+'m</span>');
        },
        slide: function( event, ui ) {
            $("#blockwidth-min").val(Math.round(ui.values[0]));
            $("#blockwidth-max").val(Math.round(ui.values[1]));

            $('.blockwidth .ui-slider-handle .value-1').empty().append(ui.values[ 0 ]+'m');
            $('.blockwidth .ui-slider-handle .value-2').empty().append(ui.values[ 1 ]+'m');
        }
    });

}



/**
 * Set the sort hidden field based on the select
 */
$('#sorting-value').val($('#sorting').val());
$('#sorting').change(function(){
    $('#sorting-value').val($(this).val());
    $('#filterform').submit();
});



/**
 * Range select filters
 */
$('input.range-selector').on('click', function() {
    if( $(this).prop('checked') == true ) {
        $(this).parents('.range-select').addClass('active');
    }else {
        $(this).parents('.range-select').removeClass('active');
    }

    $('#filterform').submit();
})


/**
 * Remove empty query strings by submitting with jquery
 */
$('#filterform').submit(function () {
    $('#filterform input').each(function() {
        if ($(this).val() == "") {
          $(this).attr("disabled", true);
        }
    })

    if($('#filterform #home_range').length) {

        var arr=[];
        $('#filterform input:checked[class=range-selector]').each(function(){
            arr.push($(this).val());
        });
        $('#home_range').val(arr.join('-'));

    }

});

/**
 * Clear button to remove all values
 */
$('#clear').on('click',function() {
    $('#filterform input').each(function(index,selected) {        
        if(selected.id == ('pricerange-min' || 'housesize-min' || 'blockwidth-min')) {
            selected.value = 0;
        } else if (selected.id == ( 'blockwidth-max')) {
            selected.value = 2000;
        } else if (selected.id == ( 'housesize-max')) {

             if($('.housesize').hasClass('hal')){
                selected.value = 5000;
            }else{
                selected.value = 60;
            }

        } else if (selected.id == 'pricerange-max') {
            selected.value = 1000000;
        } else {
            selected.value = '';
        }
    });
    $('#filterform').submit();
});




/**
 * Show hide the filters on mobile
 */
$('.property-filter .show-hide').on('click', function(index, selected) {
    $(".property-filter").toggleClass("hide-filters");
});






/**
 * Single home page tabbed content
 */
$(function () {
    $("#tabs").tabs();
});


/**
 * Floorplan switcher options
 */
$('.floorplan__switcher input[type=radio]').on('click',function(el) {
    $('.floorplan__img').removeClass('active');
    $('.floorplan__img[data-floorplan-img='+el.target.value+']').addClass('active');

    $('.floorplan__measurements').removeClass('active');
    $('.floorplan__measurements[data-floorplan-measurements='+el.target.value+']').addClass('active');
});





/**
 * Home designs slider section
 */
// get all sliders, we need to loop them due to different settings + nav
var swipers = document.querySelectorAll(".swiper-container--property-listings");
swipers.forEach(function(el,index){
    var closestSection = el.parentElement;
    var controls = closestSection.querySelector('.control');

    // slider settings
    var options = {
        speed: 600,
        loop: true,
        slidesPerView: 3,
        observer: true,
        observeParents: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
        },
        navigation:{},
        thumbs:{},
        breakpoints: {
            576 : {
                slidesPerView: 1
            },
            768 : {
                slidesPerView: 2
            }
        }
    };

    // init nav
    options.navigation = {
        nextEl: closestSection.querySelector('.swiper-next'),
        prevEl: closestSection.querySelector('.swiper-prev')
    }

    // maybe add gallery controls to the slider
    if(controls){
        options.thumbs.swiper = new Swiper(controls, {
            speed: 600,
            loop: true,
            slidesPerView: 4,
            spaceBetween: 10,
            observer: true,
            observeParents: true,
        });
    }

    // init slider
    new Swiper(el, options);
});