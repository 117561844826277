(function($) {

    $(window).on('load', function () {

        // Components loading animations
        $('.view-animation').viewportChecker({
            classToAdd: 'animated',
            offset: 20
        });

        $('.header-hamburger').click(function(){
            $('body').toggleClass('menu-open');
        });

        $('.nav-dropdown').click(function(e){
            e.preventDefault();
            e.stopPropagation();
            $(this).closest('li').toggleClass('subnav-open');
        });

        // Accordion for FAQs (jQuery)
        $('.accordion dt.active').next().slideDown()

        $('.accordion').on('click', 'dt', function () {
            $('.accordion dd').slideUp();

            if (!$(this).hasClass('active')) {
                // remove active class from any others that might be active
                $('.accordion dt.active').removeClass('active');
                $(this).addClass('active');
                $(this).next().slideDown();
            } else {
                $(this).removeClass('active');
            }
        });

        // Inline Video Funcionality
        $(document).on('click', '.inline-video-trigger', function () {
            if ($(this).data('video-id')) {
                if ($(this).hasClass('vimeo')) {
                    var iframeHTML = '<iframe src="https://player.vimeo.com/video/' + $(this).attr('data-video-id') + '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
                } else {
                    var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + $(this).attr('data-video-id') + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                }

                $(this).parent('.video-preview-container').find('.inline-video-trigger').hide();
                $(this).parent('.video-preview-container').find('.overlay').hide();
                $(this).parent('.video-preview-container').find('iframe').remove();
                $(this).parent('.video-preview-container').append(iframeHTML);
            } else {
                console.error('no video ID provided.');
            }
        });

        // // Phone Concatenation Script For Tracking
        // setTimeout(function () {
        //     $('.phone-text em').each(function () {
        //         var unsliced = $(this).text();
        //         var sliced = unsliced.slice(0, -2) + "...";
        //         $(this).text(sliced);
        //         var linked = "tel:" + unsliced.replace(/\s/g, '');
        //         $(this).click(function () {
        //             if ($(window).width() < 1000) {
        //                 window.location.href = linked;
        //             } else {
        //                 $(this).text(unsliced);
        //             }
        //         });
        //     });

        // }, 3000);

        //COUNTDOWN
        if ($('.countdown-timer').length > 0) {
            var date = new Date();
            var startMinutes = date.getMinutes();
            var startSeconds = date.getSeconds();
            var remainingSeconds = 59 - startSeconds;
            var remainingMinutes = 0;

            if (startMinutes < 15) { remainingMinutes = 15 - startMinutes - 1; }
            else if (startMinutes < 30) { remainingMinutes = 30 - startMinutes - 1; }
            else if (startMinutes < 45) { remainingMinutes = (45 - startMinutes - 1); }
            else { remainingMinutes = (60 - startMinutes - 1); }


            var count = (remainingMinutes * 60 + remainingSeconds);
            var counter = setInterval(timer, 1000); //1000 will run it every 1 second
            
            function progress(timeleft, timetotal) {
                var progressBarWidth = 100 - ((timeleft / timetotal) * 100);
                $('.progressbar .completed').animate({ width: progressBarWidth + '%' }, 500);
            }
            function timer() {
                count = count - 1;
                if (count == -1) {
                    clearInterval(counter);
                    return;
                }

                var seconds = count % 60;
                var minutes = Math.floor(count / 60);
                var hours = Math.floor(minutes / 60);
                minutes %= 60;
                hours %= 60;

                var minuteString = ' minutes and ';
                if (minutes == 1) { minuteString = ' minute and ' }
                var secondString = ' seconds';
                if (seconds == 1) { secondString = ' second' }

                progress((minutes * 60 + seconds), 900);
                document.querySelectorAll(".training-text .time-left").forEach(function (selected, index) {
                    if (seconds < 1 && minutes < 1) {
                        selected.innerHTML = $(selected).closest('.countdown-timer').data('end-text');
                    }
                    if (minutes < 1) {
                        selected.innerHTML = seconds + secondString; // watch for spelling
                    } else {
                        selected.innerHTML = minutes + minuteString + seconds + secondString; // watch for spelling
                    }
                });
                
            }
        }

        // get all sliders, we need to loop them due to different settings + nav
        var swipers = document.querySelectorAll('.swiper-container:not(.control):not(.mobile)');
        swipers.forEach(function(el,index){
            var closestSection = el.parentElement;
            var controls = closestSection.querySelector('.control');
            var slideCount = el.querySelectorAll('.swiper-slide').length;

            if(slideCount <= 1){
                return;
            }

            // slider settings
            var options = {
                speed: 600,
                loop: true,
                slidesPerView: 1,
                observer: true,
                observeParents: true,
                autoplay: {
                    delay: 10000,
                    disableOnInteraction: true,
                },
                navigation:{},
                thumbs:{},
            };

            // init nav
            options.navigation = {
                nextEl: closestSection.querySelector('.swiper-next'),
                prevEl: closestSection.querySelector('.swiper-prev')
            }

            // maybe add gallery controls to the slider
            if(controls){
                options.thumbs.swiper = new Swiper(controls, {
                    speed: 600,
                    loop: true,
                    slidesPerView: 4,
                    spaceBetween: 10,
                    observer: true,
                    observeParents: true,
                });
            }

            // init slider
            var sw = new Swiper(el, options);

            sw.on('slideChange', function () {
                
            });
        });

        // mobile sliders, like logo rows etc
        // need to loop in order to get the slide count
        var mobileSwipers = document.querySelectorAll('.swiper-container.mobile');
        mobileSwipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var slideCount = el.querySelectorAll('.swiper-slide').length;

            var options = {
                speed:600,
                slidesPerView: 2,
                watchOverflow: true,
                loop: true,
                simulateTouch: false,
                observer: true,
                observeParents: true,
                pagination:{
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                    // 576 : {
                    //     slidesPerView: 3
                    // },
                    // 768 : {
                    //     slidesPerView: 4
                    // },
                    992: {
                        slidesPerView: 3,
                    },
                    1366: {
                        slidesPerView: slideCount,
                    }
                }
            };

            // init slider
            new Swiper(el, options);
        });


        // Enquiry Modal
        $('#formModal').on('show.bs.modal', function(){
            var selectedPlan = $(".floorplan__switcher-container input:checked").val();

            $('#floorplan-input').val('Floor Plan Option '+(parseInt(selectedPlan) + 1));
        });
    });

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('.site-header').addClass('scrolled');
        } else {
            $('.site-header').removeClass('scrolled');
        }
    });

    // Smooth Scroll To Anchor
    $(document).on('click', 'a[href*="#"]:not(.ui-tabs-anchor):not([href*="/page/"])', function (event) {
        event.preventDefault()
        var target = $(this).attr('href');

        if ($(target).length) {
            $('html, body').animate({
                scrollTop: $(target).offset().top - $('.site-header').outerHeight()
            }, 1500)
        }
    });

})( jQuery );